@font-face{
  font-family: 'Kozuka';
  src: local("Kozuka Gothic Pro B"),
  url("./fonts/Font.otf") format("otf");
}

body{
  font-family: 'Kozuka';
  font-size:medium;
  color: #4D4D4D;
}

.mytextb{
  color: #4D4D4D;
}
.mytextw{
  color: #E6E6E6;
}
.MyBgColorGray{
  background-color: #B3B3B3;
}

.MyBgColorGreen{
  background-color: #09C100;
}

.MyColorGreen{
  color: #09C100;
}

input{
  font-size:medium;
}

.MyColorBlue{
  color: #0008B8;
}

.MyColorSky{
  color: #008FFF;
}

.MyColorPink{
  color: #FA05FF;
}

.MyColorRed{
  color: #FF0000;
}

.MyColorOrange{
  color: #FF2B05;
}

.MyColorYellow{
  color: #FF6600;
}

.MyBgColorBlue{
  background-color: #0008B8;
}

.MyBgColorSky{
  background-color: #008FFF;
}

.MyBgColorPink{
  background-color: #FA05FF;
}

.MyBgColorRed{
  background-color: #FF0000;
}

.MyBgColorOrange{
  background-color: #FF2B05;
}

.MyBgColorYellow{
  background-color: #FF6600;
}
.MyWhite{
  color: #E6E6E6;
}